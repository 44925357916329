import {
  AppBar,
  Link,
  Stack,
  Toolbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export interface NavBarProps {
  showLogout: boolean,
}

const NavBar = ({ showLogout }: NavBarProps) => {
  const { t } = useTranslation();

  return (
    <AppBar sx={{ bgcolor: 'brand.main' }} position="relative">
      <Toolbar sx={{ justifyContent: 'space-between' }} className="focusVisible--darkBackgroundLightOutline">
        <Stack direction="row" spacing={2}>
          <Link to="/" component={RouterLink} color="brand.contrastText">{t('layout.navBar.home')}</Link>

          <Link
            href="https://www.leadershipchallenge.com/leadership-assessments/student-lpi-self"
            color="brand.contrastText"
            target="_blank"
          >
            {t('layout.navBar.about')}
          </Link>

          <Link
            href="https://customer.wiley.com/CGI-BIN/lansaweb?procfun+shopcart3+SH3FN29+funcparms+confid(a0060):SL0088"
            color="brand.contrastText"
            target="_blank"
          >
            {t('layout.navBar.purchase')}
          </Link>
        </Stack>

        {showLogout && (
          <Stack>
            <Link to="/logout" component={RouterLink} color="brand.contrastText" data-testid="LogoutButton-test-id">
              {t('layout.navBar.logout')}
            </Link>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
