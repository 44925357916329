type RouteDefinition = {
  name: string,
  path: string,
};

export const ROUTES = {
  auth: 'auth',
  authFromLegacy: 'authFromLegacy',
  register: 'register',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword',
  home: 'home',
  demographics: 'demographics',
  instructions: 'instructions',
  assessment: 'assessment',
  assessmentResults: 'assessmentResults',
  error: 'error',
  logout: 'logout',
  passwordExpired: 'passwordExpired',
  acceptTerms: 'acceptTerms',
  viewAssessments: 'viewAssessments',
  reports: 'reports',
  reportCustomization: 'reportCustomization',
  invitation: 'invitation',
  yourAssessments: 'yourAssessments',
  contactInfoSettings: 'contactInfoSettings',
  catch: 'catch',
};

export const ROUTES_DEFINITIONS: { [key: string]: RouteDefinition } = {
  // Assessment
  [ROUTES.demographics]: {
    name: ROUTES.demographics,
    path: '/demographics/:assessmentId',
  },
  [ROUTES.instructions]: {
    name: ROUTES.instructions,
    path: '/instructions/:assessmentId',
  },
  [ROUTES.assessment]: {
    name: ROUTES.assessment,
    path: '/assessment/:assessmentId',
  },
  [ROUTES.assessmentResults]: {
    name: ROUTES.assessmentResults,
    path: '/assessment/:assessmentId/view-results',
  },
  // Auth
  [ROUTES.auth]: {
    name: ROUTES.auth,
    path: '/login',
  },
  [ROUTES.authFromLegacy]: {
    name: ROUTES.authFromLegacy,
    path: '/login-from-legacy',
  },
  [ROUTES.register]: {
    name: ROUTES.register,
    path: '/register',
  },
  [ROUTES.forgotPassword]: {
    name: ROUTES.forgotPassword,
    path: '/forgot-password',
  },
  [ROUTES.resetPassword]: {
    name: ROUTES.resetPassword,
    path: '/reset-password',
  },
  [ROUTES.logout]: {
    name: ROUTES.logout,
    path: '/logout',
  },
  [ROUTES.passwordExpired]: {
    name: ROUTES.passwordExpired,
    path: '/password-expired',
  },
  [ROUTES.acceptTerms]: {
    name: ROUTES.acceptTerms,
    path: '/accept-terms',
  },
  // Error
  [ROUTES.error]: {
    name: ROUTES.error,
    path: '/error',
  },
  // Home
  [ROUTES.home]: {
    name: ROUTES.home,
    path: '/',
  },
  // Admin
  [ROUTES.viewAssessments]: {
    name: ROUTES.viewAssessments,
    path: '/admin/view-assessments',
  },
  [ROUTES.reports]: {
    name: ROUTES.reports,
    path: '/admin/reports',
  },
  [ROUTES.reportCustomization]: {
    name: ROUTES.reportCustomization,
    path: '/admin/reports/customization',
  },
  // Leader
  [ROUTES.invitation]: {
    name: ROUTES.invitation,
    path: '/invitation/:invitationId',
  },
  [ROUTES.yourAssessments]: {
    name: ROUTES.yourAssessments,
    path: '/your-assessments',
  },
  // Account
  [ROUTES.contactInfoSettings]: {
    name: ROUTES.contactInfoSettings,
    path: '/contact-settings',
  },
  // Catch
  [ROUTES.catch]: {
    name: ROUTES.catch,
    path: '*',
  },
};

type RouteParams = { [key: string]: string };
export const reverseRoute = (routeName: string, params?: RouteParams): string => {
  let routePath = ROUTES_DEFINITIONS[routeName].path;
  if (params) {
    Object.entries(params).forEach(([paramKey, paramValue]) => {
      routePath = routePath.replace(`:${paramKey}`, paramValue);
    });
  }

  return routePath;
};
